var $ = require("jquery");
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* ====================== SERVICES SLIDER ======================  */
// Iterate over each instance of `.project-showcase__slider`
$('.project-showcase__slider').each(function() {
    const $slider = $(this);

    // Find the specific elements within the parent container
    const $swiperElement = $slider.find('.swiper')[0];
    const $scrollbarElement = $slider.parent().find('.project-showcase__slider__scrollbar');
    const $nextButton = $slider.find('.project-showcase__slider__next')[0];
    const $prevButton = $slider.find('.project-showcase__slider__prev')[0];

    // Initialise Swiper for each instance
    new Swiper($swiperElement, {
        slidesPerView: 1.1,
        spaceBetween: 15,
        loop: false,
        scrollbar: {
            el: $scrollbarElement,
            hide: false,
            draggable: true
        },
        navigation: {
            nextEl: $nextButton,
            prevEl: $prevButton,
        },
        breakpoints: {
            768: {
                slidesPerView: 1.5,
            },
            1024: {
                slidesPerView: 2,
            },
        }
    });
});


var contentSlider = new Swiper('.content-slider__slider', {
    slidesPerView: 1.25,
    spaceBetween: 15,
    loop: false,

    scrollbar: {
        el: ".content-slider__slider__scrollbar",
        hide: false,
        draggable: true
    },

    breakpoints: {
        567: {
            slidesPerView: 2.25,
        },

        768: {
            slidesPerView: 2.5,
        },
    }
});

// var galleryReelSlider = new Swiper('.gallery-slider__reel', {
//     slidesPerView: "auto",
//     spaceBetween: 12,
//     loop: true,
//     watchSlidesProgress: true,
// });

// var galleryMainSlider = new Swiper(".gallery-slider__main", {
//     slidesPerView: 1,
//     spaceBetween: 0,
//     loop: true,
//     effect: "fade",
//     fadeEffect: {
//         crossFade: true,
//     },

//     thumbs: {
//         swiper: galleryReelSlider,
//     },

//     navigation: {
//         nextEl: ".gallery-slider__bottom__next",
//         prevEl: ".gallery-slider__bottom__prev",
//     },
// });

var latestTestimonialsSlider = new Swiper(".testimonials-slider__slider", {
    slidesPerView: 1,
    spaceBetween: 15,
    centeredSlides: true,
    
    navigation: {
        nextEl: ".testimonials-slider__nav__next",
        prevEl: ".testimonials-slider__nav__prev",
    },

    breakpoints: {
        1024: {
            slidesPerView: "auto",
        },
    }
});

var latestNews = new Swiper('.latest-news__slider', {
    slidesPerView: 1.2,
    spaceBetween: 15,
    loop: false,

    // scrollbar: {
    //     el: ".content-slider__slider__scrollbar",
    //     hide: false,
    // },

    breakpoints: {
        567: {
            slidesPerView: 2.2,
        },

        768: {
            slidesPerView: 3,
        },

        1200: {
            slidesPerView: 4,
        },
    }
});

var storeSlider = new Swiper('.cast-iron-store__slider', {
    slidesPerView: 1.2,
    spaceBetween: 15,
    loop: false,

    // scrollbar: {
    //     el: ".content-slider__slider__scrollbar",
    //     hide: false,
    // },

    breakpoints: {
        567: {
            slidesPerView: 2.2,
        },

        768: {
            slidesPerView: 3,
        },

        1200: {
            slidesPerView: 5,
        },
    }
});

var relatedStoreSlider = new Swiper('.related-components__slider', {
    slidesPerView: 1.2,
    spaceBetween: 15,
    loop: false,

    breakpoints: {
        567: {
            slidesPerView: 2.2,
        },

        768: {
            slidesPerView: 3,
        },

        1200: {
            slidesPerView: 4,
        },
    }
});

var galleryReelSlider = new Swiper('.gallery-slider__reel', {
    slidesPerView: "auto",
    spaceBetween: 12,
    loop: true,
    watchSlidesProgress: true,
    direction: window.innerHeight < 750 ? 'vertical' : 'horizontal',
});

var galleryMainSlider = new Swiper(".gallery-slider__main", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },

    thumbs: {
        swiper: galleryReelSlider,
    },

    navigation: {
        nextEl: ".gallery-slider__bottom__next",
        prevEl: ".gallery-slider__bottom__prev",
    },
});

// window.addEventListener('resize', () => {
//     const newDirection = window.innerWidth > 768 ? 'vertical' : 'horizontal';
//     if (galleryReelSlider.params.direction !== newDirection) {
//         galleryReelSlider.changeDirection(newDirection);
//     }
// });

// const initialDirection = window.innerWidth > 768 ? 'vertical' : 'horizontal';
// if (galleryReelSlider.params.direction !== initialDirection) {
//     galleryReelSlider.changeDirection(initialDirection); 
// }

if (galleryReelSlider && galleryReelSlider.el && galleryReelSlider.el.classList) {
    const initialDirection = window.innerWidth > 1025 ? 'vertical' : 'horizontal';
    galleryReelSlider.changeDirection(initialDirection);

    window.addEventListener('resize', () => {
        const newDirection = window.innerWidth > 1025 ? 'vertical' : 'horizontal';
        if (galleryReelSlider.params.direction !== newDirection) {
            galleryReelSlider.changeDirection(newDirection);
        }
    });
}


var wishlistSlider = new Swiper('.wishlist__images', {
    slidesPerView: 1.2,
    spaceBetween: 15,
    loop: false,

    scrollbar: {
        el: ".wishlist__images__scrollbar",
        hide: false,
        draggable: true
    },

    navigation: {
        nextEl: ".wishlist__images__next",
        prevEl: ".wishlist__images__prev",
    },
     breakpoints: {
        600: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        },
    }
});

const heroSwiper = new Swiper('.masthead__gallery-bg', {
    effect: 'fade',
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false, // Ensures autoplay doesn't stop on hover
    },
    fadeEffect: {
        crossFade: true,
    },
    speed: 2000,
});

