// import { Fancybox } from "@fancyapps/fancybox";
import { Fancybox } from "@fancyapps/ui";

jQuery(document).ready(function($) {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && window.innerHeight > window.innerWidth) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh+'px');
        document.documentElement.style.setProperty('--staticvh', vh+"px");
        window.addEventListener('resize', function() {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', vh+'px');
        });
    }


 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

/* ====================== FUNCTIONS ======================  */

function debounce(func, delay) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            func.apply(context, args);
        }, delay);
    };
}

/* ====================== PRODUCT FEATURES ======================  */

$('.product-features__accordion:not(.active) .product-features__accordion__content').hide();

// When the header is clicked
$('.product-features__accordion__header').click(function() {
    var $accordion = $(this).closest('.product-features__accordion');

    // Close all other accordions
    $('.product-features__accordion').not($accordion).removeClass('active')
        .find('.product-features__accordion__content').slideUp();

    // Toggle the clicked accordion
    $accordion.toggleClass('active')
        .find('.product-features__accordion__content').slideToggle();
});

/* ====================== STICKY HEADER ======================  */
window.onscroll = function() { toggleStickyHeader(); };

function toggleStickyHeader() {
    var header = document.querySelector('.header');
    var sticky = header.offsetTop;

    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
}

var defaultImageUrl = $('.sub-menu__images .menu-item-image img').attr('src');

$('.sub-menu__wrap li').hover(
    function() {
        var imageUrl = $(this).data('image-url');
        var $submenu = $(this).closest('.sub-menu'); // Isolate to the specific submenu

        if (imageUrl) {
            $submenu.find('.menu-item-image img').attr('src', imageUrl); // Update only in the specific submenu
        }
    }
);
/* ====================== MOBILE MENU ======================  */

$('.header-bottom__menu').on('click', function() {
    $(this).toggleClass('active');
    $('.navigation').toggleClass('active');
    $('body').toggleClass('noscroll');

    if($(this).hasClass('active')) {
        $('.header-bottom__menu >span').text('ClOSE MENU');
    } else {
        $('.header-bottom__menu >span').text('OPEN MENU');
    }
});

function toggleSubMenu() {
    var windowWidth = $(window).width();

    if (windowWidth < 1200) {
        // Remove any existing event handlers to avoid duplication
        $(document).off('click', 'li.menu-item-has-children > a');
        $('.sub-menu__close').off('click');

        // Handle click events on menu items with children
        $(document).on('click', 'li.menu-item-has-children > a', function(e) {
            e.preventDefault(); // Prevent the link from navigating

            var $menuItem = $(this).closest('li.menu-item-has-children');
            var $submenu = $menuItem.find('.sub-menu');

            // Remove active class from other sub-menus
            $('.sub-menu').not($submenu).removeClass('active');

            // Toggle the active class on the current submenu
            $submenu.toggleClass('active');
        });

        // Handle click event on .sub-menu__close button to close the submenu
        $(document).on('click', '.sub-menu__close', function(e) {
            e.preventDefault();
            $(this).closest('.sub-menu').removeClass('active');
        });

        // Close submenus when clicking outside
        $(document).on('click', function(e) {
            if (!$(e.target).closest('li.menu-item-has-children').length) {
                $('.sub-menu').removeClass('active');
            }
        });
    }
}

// Call toggleSubMenu on initial load
toggleSubMenu();

// Reinitialise event handlers on window resize
$(window).resize(function() {
    toggleSubMenu();
});

/* ====================== GALLERY ======================  */

jQuery(document).ready(function($) {
    // Hide options by default if the filter item doesn't have .active
    $('.gallery__filter__item').each(function() {
        if (!$(this).hasClass('active')) {
            $(this).find('.gallery__filter__options').hide();
        }
    });

    // On click, toggle the clicked filter's options without affecting others
    $('.gallery__filter__heading').on('click', function() {
        $(this).next('.gallery__filter__options').slideToggle();
    });
});



/* ====================== QUOTE FORM ======================  */

function wrapButtons() {
    $('input.gform_next_button').each(function () {
        if (!$(this).parent().hasClass('gform_next_wrapper')) {
            $(this).wrap('<div class="gform_next_wrapper"></div>');
        }
    });

    $('input.gform_previous_button').each(function () {
        if (!$(this).parent().hasClass('gform_prev_wrapper')) {
            $(this).wrap('<div class="gform_prev_wrapper"></div>');
        }
    });

    $('input#gform_submit_button_6').each(function () {
        if (!$(this).parent().hasClass('gform_submit_wrapper')) {
            $(this).wrap('<div class="gform_submit_wrapper"></div>');
            $(this).before('<div class="arrow"></div>'); // Add the arrow before the submit button
        }
    });
}

// Initial wrapping
wrapButtons();

// Reapply wrapping after AJAX renders a new form step
$(document).on('gform_post_render', function () {
    wrapButtons();
});


$('input#gform_submit_button_4, input#gform_submit_button_1, input#gform_submit_button_3, input#gform_submit_button_2, input#gform_submit_button_5').each(function() {
    $(this).wrap('<div class="gform_submit_wrapper"></div>');
    $(this).before('<div class="arrow"></div>');
});

function applyUnitFieldLogic() {
    $('.unit-field').each(function () {
        const unitField = $(this); // The current .unit-field
        const fieldContainer = unitField.find('.ginput_container'); // Input container
        const inputField = fieldContainer.find('input[type="number"]'); // Numeric input field
        const unitDropdown = fieldContainer.parent().next('.unit-selector ').find('select');

        // Check if the input field exists and hasn't already been processed
        if (inputField.length && unitDropdown.length) {
            // Update the placeholder based on the selected unit
            function updatePlaceholder() {
                const selectedUnit = unitDropdown.val();
                if (selectedUnit === 'mm') {
                    inputField.attr('placeholder', 'Enter size in mm');
                } else if (selectedUnit === 'cm') {
                    inputField.attr('placeholder', 'Enter size in cm');
                } else if (selectedUnit === 'in') {
                    inputField.attr('placeholder', 'Enter size in inches');
                }
            }

            // Update the hidden field and placeholder when the dropdown value changes
           unitDropdown.on('change', function () {
                const selectedUnit = $(this).val();
                updatePlaceholder(); // Update placeholder
            });

            // Initialize the dropdown and placeholder
            updatePlaceholder();
        }
    });
}

// Apply the logic on page load
applyUnitFieldLogic();

// Reapply the logic after each AJAX step
$(document).on('gform_post_render', function () {
    applyUnitFieldLogic();
});
/* ====================== WISHLIST ======================  */

// Retrieve the wishlist from localStorage
function getWishlist() {
    return JSON.parse(localStorage.getItem('wishlist')) || [];
}

// Save the wishlist to localStorage
function setWishlist(wishlist) {
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
}

// Update the wishlist counter in the UI
function updateWishlistCounter() {
    const wishlist = getWishlist();
    const counter = wishlist.length;
    $('.wishlist-counter').text(counter);
}

// Update wishlist buttons (add/remove states)
function updateWishlistButtons() {
    const wishlist = getWishlist();

    $('.wishlist-btn, .quick-wishlist').each(function () {
        const imageId = $(this).data('image-id')?.toString();

        if (wishlist.includes(imageId)) {
            $(this).addClass('in-wishlist');
            $(this).find('span').text('Remove');
        } else {
            $(this).removeClass('in-wishlist');
            $(this).find('span').text('Add to Wishlist');
        }
    });

    $('.remove-wishlist-btn').each(function () {
        const imageId = $(this).data('image-id')?.toString();

        if (!wishlist.includes(imageId)) {
            $(this).closest('.wishlist-item').remove(); // Remove the item from the DOM
        }
    });
}

// Add or remove an item from the wishlist
function toggleWishlist(imageId) {
    let wishlist = getWishlist();

    if (wishlist.includes(imageId.toString())) {
        wishlist = wishlist.filter(id => id !== imageId.toString());
        showRemovalNotification(); // Show notification for removal
    } else {
        wishlist.push(imageId.toString());
        animateWishlistIcon();
        showAdditionNotification(); // Show notification for addition
    }

    setWishlist(wishlist);
    updateWishlistButtons();
    updateWishlistCounter();
    sendWishlistToServer();
}

// Remove an item from the wishlist
function removeWishlistItem(imageId) {
    let wishlist = getWishlist();

    // Filter out the item from the wishlist
    wishlist = wishlist.filter(id => id !== imageId.toString());

    setWishlist(wishlist);
    updateWishlistButtons();
    updateWishlistCounter();
    sendWishlistToServer();
    showRemovalNotification(); // Optional: Show removal notification
}

// Animate the wishlist icon when an item is added
function animateWishlistIcon() {
    const $icon = $('.header-top__wishlist i');
    $icon.addClass('wishlist-animated');

    setTimeout(() => {
        $icon.removeClass('wishlist-animated');
    }, 400);
}

function showAdditionNotification() {
    const notification = $('#wishlist-notification');
    const message = 'Added to wishlist'; 

    notification.find('.wishlist-notification__message').text(message);
    notification.addClass('show');

    setTimeout(() => {
        notification.removeClass('show');
    }, 4000);
}

function showRemovalNotification() {
    const notification = $('#wishlist-notification');
    const message = 'Removed from wishlist'; 

    notification.find('.wishlist-notification__message').text(message);
    notification.addClass('show');

    setTimeout(() => {
        notification.removeClass('show');
    }, 4000);
}

function sendWishlistToServer() {
    const wishlist = getWishlist();

    $.ajax({
        url: ajax_params.ajax_url,
        method: 'POST',
        data: {
            action: 'load_wishlist',
            wishlist_items: wishlist
        },
        success: function (response) {
            $('.wishlist__images > .swiper-wrapper').html(response);
        },
        error: function (xhr, status, error) {
            console.error('AJAX Error:', error);
        }
    });
}

// Initialize Fancybox as usual
Fancybox.bind("[data-fancybox='prod-gallery']", {
    Toolbar: {
        display: {
            left: [],
            middle: ["prev", "infobar", "next"],
            right: ["flipX" , "fullscreen", "thumbs", "close"],
        },
    },
    Thumbs: {
        type: "classic",
        showOnStart: false,
    },
});

// Intercept clicks to exclude wishlist buttons
document.querySelectorAll("[data-fancybox='prod-gallery']").forEach(function (element) {
    element.addEventListener("click", function (event) {
        // If a wishlist button is clicked, prevent Fancybox but allow other handlers
        const wishlistButton = event.target.closest(".quick-wishlist, .wishlist-btn");
        if (wishlistButton) {
            console.log("Wishlist button clicked, Fancybox prevented.");
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();

            const imageId = wishlistButton.getAttribute("data-image-id");
            if (imageId) {
                toggleWishlist(imageId);
            } else {
                console.warn("No data-image-id found on click.");
            }

            return;
        }

        // Manually trigger Fancybox for other clicks
        Fancybox.fromEvent(event);
    });
});

// Debounced wishlist click handler
$(document).on('click', '.wishlist-btn, .quick-wishlist', debounce(function (e) {
    e.preventDefault(); // Prevent default behavior like navigation
    console.log("Wishlist functionality triggered.");
    const imageId = $(this).data('image-id');
    if (imageId) {
        toggleWishlist(imageId);
    } else {
        console.warn("No data-image-id found on click.");
    }
}, 200));



$(document).on('click', '.remove-wishlist-btn', function () {
    const imageId = $(this).data('image-id');
    if (imageId) {
        removeWishlistItem(imageId);
    } else {
        console.warn("No data-image-id found on remove button click.");
    }
});


updateWishlistCounter();
updateWishlistButtons();
sendWishlistToServer();

$(document).on('click', '#show-wishlist', function () {
    const $wishlistWrap = $('.wishlist__slider__wrap');
    const $buttonText = $(this).find('span');

    // Slide toggle the wishlist wrap
    $wishlistWrap.slideToggle(300, function () {
        // Update button text based on visibility
        if ($wishlistWrap.is(':visible')) {
            $buttonText.text('Hide Wishlist');
        } else {
            $buttonText.text('Show Wishlist');
        }
    });
});

$(document).on('submit', '#gform_3', function (e) {
    const wishlist = getWishlist(); // Get wishlist from localStorage
    const wishlistField = $('#field_3_11 input'); // Select the hidden field input

    if (wishlist.length > 0) {
        // Perform an AJAX request to fetch the details for the wishlist items
        $.ajax({
            url: ajax_params.ajax_url,
            method: 'POST',
            data: {
                action: 'get_wishlist_details_table',
                wishlist_items: wishlist,
            },
            async: false, // Ensure the data is ready before form submission
            success: function (response) {
                if (response.success) {
                    wishlistField.val(response.data); // Populate the field with the HTML table
                } else {
                    console.error('Failed to fetch wishlist details:', response.data);
                }
            },
            error: function (xhr, status, error) {
                console.error('AJAX Error:', error);
            }
        });
    } else {
        wishlistField.val('<p>No items in wishlist</p>');
    }
});
});

